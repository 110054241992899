import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { REDEEM_MEMBERSHIP_FROM_EMAIL } from '@app/core/forgot-password/forgot-password.component';
import { CLAIM_CODE_PARAM_KEY } from '@app/shared/hornbill-params';

import { AnalyticsService } from './analytics.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private attemptedPathService: AttemptedPathService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn$().pipe(
      map(isLoggedIn => isLoggedIn || this.handleUnauthenticated(state.url, route)),
      catchError(() => observableOf(this.handleUnauthenticated(state.url, route))),
    );
  }

  private handleUnauthenticated(url: string, route: ActivatedRouteSnapshot): boolean {
    this.analyticsService.trackDirectLinksToLoggedInResources(url);
    this.attemptedPathService.setAttemptedPath(url);

    if (!!route.queryParams[CLAIM_CODE_PARAM_KEY]) {
      this.authService.goLogin({ path: url, fragment: REDEEM_MEMBERSHIP_FROM_EMAIL });
    } else {
      this.authService.goLogin({ path: url });
    }

    return false;
  }
}
