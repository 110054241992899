import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://pasadena.app.1life.com',
  myoneServer: 'https://pasadena.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-pasadena',
  },
  stripePublishableKey:
    'pk_test_51NtDlIAbJ7U5PzKhiYzZrzc4hxWLcQKlanpxWLFWmi5amYrqp1XGChxCDqzgCfBE0d8hexARMqlyRgHlasNbOjql00Bav6gCn7',
  launchDarklyClientId: '604ffb7c824e970bccf18017',
};
